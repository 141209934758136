/* eslint-disable camelcase */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import '../../../styles/main.scss';
import Modal from '@lib/components/v2/Modal';

import { DetailMatch } from '@containers';

import {
  isWebRTCSupported,
  copyOpenSafari,
  isAndroidDevice,
  isBrowserPermitted,
  setCookie,
  getCookie,
  isShownPrivacy
} from '@lib/Utils';
import APIs from '@services/APIs';
import { localizedString, getLocalizedIdvcContent } from '@languages';
import parse from 'html-react-parser';
import draftToHtml from 'draftjs-to-html';
import { LoadingSpinner } from '@FLOW_V2_FLOW/components';
import { PrivacyScreen } from '@lib/pages/v2/PrivacyScreen';
import Message from '@lib/components/v2/Message';

import {
  COUNTRIES,
  DETAIL_MATCH,
  DETAIL_MATCH_ONLY_UNIVERSAL_URL,
  ENABLE_ONE_DOC_CONDITION,
  UNIVERSAL_URL_PATH,
  VOI_FLOW_V2_ADDITIONAL_DOC,
  VOI_FLOW_V2_AUTO_SELECT_DOCS,
  VOI_FLOW_V2_REQUIRED_DOC_CONFIG,
  VOI_FLOW_V2_SHOW_WELCOME
} from '@spotMobileConfig';

import {
  Error500,
  InternetCut,
  DeviceIncompatibleOpenChrome,
  Timeout,
  Error400
} from '../../errors';
import {
  FaceScan,
  Welcome,
  DocumentSelection,
  Capture,
  NameMatch,
  VerifyDetails,
  AlternateFlow,
  CaptureExtra,
  Success
} from '..';
import { LoadingBar, Language } from '../../components';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      tokenId: '',
      geolocation: '',
      accepted: false,
      cancelled: false,
      confirm: false,
      confirmFR: false,
      error: null,
      tenMinsLeft: false,
      compatible: true,
      completed: false,
      isLandscape: false,
      isProcessing: false,
      isUploading: false,
      uploadBar: 0,
      webrtc: {
        todo: null,
        status: true
      },
      redirect: false,
      verify: false,
      showLanguageSelectionPrompt: false,
      showDocumentSelection: !isShownPrivacy('VOI_FLOW_V2'),
      currentDocument: {
        index: -1,
        type: '',
        isProcessing: false
      },
      selectedDocumentList: [],
      showNameMatch: false,
      prevScreenState: null, // VerifyDetails state for NameMatch/Back
      showCaptureExtra: false,
      frRetryAttemptCount: 0,
      selfieFR: false,
      showWelcome: VOI_FLOW_V2_SHOW_WELCOME,
      showDetailMatch: false
    };

    this.input = null;

    this.handleComplete = this.handleComplete.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
    this.handleExit = this.handleExit.bind(this);
  }

  async componentDidMount() {
    const { verify } = this.props;
    this.setState({ verify });

    const { status: compatible, todo, os } = await isWebRTCSupported();

    // Init device compatible
    if (window.location.search === '?flow=alternate') {
      // for testing
      this.setState({ compatible, webrtc: { todo: 'ALT_FLOW', status: false } });
    } else {
      this.setState({ compatible, webrtc: { todo, status: compatible } });
    }

    if (os === 'iOS') {
      document.addEventListener(
        'touchmove',
        (event) => {
          if (event.scale !== 1) {
            event.preventDefault();
          }
        },
        { passive: false }
      );
    }

    // Detect if internet cut
    window.addEventListener('offline', () =>
      this.setState({
        error: {
          component: InternetCut,
          props: {}
        }
      })
    );
    // Detect if internet re connected
    window.addEventListener('online', () => {
      this.setState({
        error: {
          component: InternetCut,
          props: {
            isOnline: true,
            onGoBack: this.handleInternetReconnect
          }
        }
      });
    });

    const transToken = document.querySelector('body').getAttribute('data-id');
    const storedTransToken = getCookie('transToken');

    if (transToken === storedTransToken) {
      if (getCookie('_permission') === 1) {
        // Capturing ID
        this.setState({ step: 0, accepted: true });
        setCookie('_permission', 0, -7);
      } else if (getCookie('_permission') === 2) {
        // Liveness step
        this.setState({ step: COUNTRIES ? 6 : 5, accepted: true });
        setCookie('_permission', 0, -7);
      }

      const FRAttempt = parseInt(getCookie('retryAttempt'), 10) || parseInt(getCookie('retry'), 10);
      if (FRAttempt) {
        this.setState({
          step: 3
        });
      }
    } else {
      setCookie('transToken', transToken, -7);
    }

    // Landscape
    this.handleOrientationChange();
    this.listenOrientationChange();

    // Show detail match
    const path = window.location.pathname;
    const detailMatch = getCookie('detailMatch');
    if ((!detailMatch || detailMatch !== 'yes') && DETAIL_MATCH && DETAIL_MATCH.length > 0) {
      let showDetailMatch = true;

      if (DETAIL_MATCH_ONLY_UNIVERSAL_URL && path !== UNIVERSAL_URL_PATH) {
        showDetailMatch = false;
      }
      this.setState({
        showDetailMatch
      });
    }

    // Start the timeout for 1 hour.
    let countdown = 60 * 60;
    const timerId = setInterval(() => {
      if (countdown === 600) {
        this.setState({
          tenMinsLeft: true
        });
      }

      if (countdown <= 0) {
        this.setState({
          tenMinsLeft: false,
          error: {
            component: Timeout,
            props: {}
          }
        });
        clearInterval(timerId);
      }
      countdown--;
    }, 1000);
  }

  /**
   * Unbind the event listener
   */
  componentWillUnmount() {
    this.removeOrientationChangeListener();
  }

  // eslint-disable-next-line react/sort-comp
  listenOrientationChange = () => {
    if (window.screen.orientation) {
      window.screen.orientation.addEventListener('change', this.handleOrientationChange);
    } else {
      window.addEventListener('orientationchange', this.handleOrientationChange);
    }
  };

  removeOrientationChangeListener = () => {
    if (window.screen.orientation) {
      window.screen.orientation.removeEventListener('change', this.handleOrientationChange);
    } else {
      window.removeEventListener('orientationchange', this.handleOrientationChange);
    }
  };

  handleOrientationChange = () => {
    let isLandscape;
    if (window.screen.orientation) {
      isLandscape = window.screen.orientation.type.includes('landscape');
    } else {
      isLandscape = window.orientation !== 0;
    }
    this.setState({ isLandscape });
  };

  /**
   * When all flows have been completed.
   *
   * @param {Object} state
   * @return {Void}
   */
  handleComplete({ sessionId: id, lr, liveness }) {
    const { tokenId, frRetryAttemptCount } = this.state;
    /**
     * Upload face scan video.
     */
    const data = {
      id,
      lr,
      actions: `Smile, Turn head ${lr ? 'left' : 'right'}`,
      tokenId,
      isEngineV4: true
    };
    if (liveness) {
      data.success = 1;
    }

    this.setState({ isProcessing: true });

    APIs.uploadVideo(data, {}, '/api/v4')
      .then(({ status, token, msg: error, action = null }) => {
        if (VOI_FLOW_V2_ADDITIONAL_DOC && !ENABLE_ONE_DOC_CONDITION) {
          this.setState({
            isProcessing: false,
            showCaptureExtra: true,
            isUploading: false
          });
        }

        if (status !== 'success') {
          if (action === 'ALTERNATE') {
            this.setState({
              webrtc: { todo: 'ALT_FLOW', status: false },
              step: 2,
              showCaptureExtra: false
            });
            return;
          }
          console.error('video upload failed', { data, error });
          throw new Error(error);
        }

        if (!VOI_FLOW_V2_ADDITIONAL_DOC || ENABLE_ONE_DOC_CONDITION) {
          this.handleFinish(token);
        }
      })
      .catch((err) => {
        if (err && err.response && err.response.status === 400 && frRetryAttemptCount < 2) {
          const error = {
            component: Error400,
            props: {
              onRetryAgain: () => {
                this.setState({
                  step: 2,
                  showCaptureExtra: false,
                  error: null,
                  frRetryAttemptCount: frRetryAttemptCount === 0 ? 1 : frRetryAttemptCount + 1
                });
              }
            }
          };
          this.setState({ error, isProcessing: false, isUploading: false });
        } else {
          const error = {
            component: Error500,
            props: {
              onTryAgain: () => {
                this.setState({ step: 2, showCaptureExtra: false, error: null });
              }
            }
          };
          this.setState({ error, isProcessing: false, isUploading: false });
        }
      });
  }

  handleCompleteAlternate = () => {
    setCookie('retryAsf', 'no', -10);
    setCookie('retry', null, -7);
    setCookie('retryCaptureWithVideoStream', null, -7);
    setCookie('retryAttempt', null, -7);
    setCookie('idCaptureAttempt', 0, -7);
    setCookie('detailMatch', 'false', -1);
    setCookie('_permission', null, -7);

    if (VOI_FLOW_V2_ADDITIONAL_DOC && !ENABLE_ONE_DOC_CONDITION) {
      this.setState({ isProcessing: false, isUploading: false, showCaptureExtra: true });
    } else {
      this.handleFinish();
    }
  };

  /**
   * Go back to pev step.
   *
   * @return {Void}
   */
  handleGoBack() {
    const { step } = this.state;
    if (step && step > 0) {
      this.setState(({ step }) => ({ step: step - 1 }));
    } else {
      this.setState({ accepted: false });
    }
  }

  /**
   * Handle internet reconnection
   *
   * @return {Void}
   */
  handleInternetReconnect = () => {
    this.setState(({ step }) => ({ step: step - 1, error: null }));
  };

  /**
   * Cancel the session.
   *
   * @return {Void}
   */
  handleExit() {
    this.setState({ confirm: false });
    this.handleGoBack();
  }

  handleFRGoBack = () => {
    // For temporary fix, we let use go back to capture Id screen
    if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
      this.setState({
        showDocumentSelection: false,
        selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
        currentDocument: {
          index: 0,
          type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
          isProcessing: false
        }
      });
    } else {
      this.setState({
        showDocumentSelection: true,
        selectedDocumentList: [],
        step: 0,
        currentDocument: {
          index: -1,
          type: '',
          isProcessing: false
        }
      });
    }
  };

  handleOnCaptured = (card) => {
    const { selectedDocumentList } = this.state;
    const docList = selectedDocumentList.map((doc) => {
      let temp = { ...doc };
      if (doc.type === card.type) {
        temp = { ...card, captured: true };
        const attempts = temp.attempts || 0;
        temp.attempts = attempts + 1;
      }
      return temp;
    });

    this.setState({
      selectedDocumentList: docList
    });
  };

  handleOnExtracted = (card, token = null) => {
    const { selectedDocumentList } = this.state;
    if (token !== null) {
      this.setState({
        tokenId: token
      });
    }

    const docList = selectedDocumentList.map((doc) => {
      const temp = { ...doc };
      if (doc.type === card.type) {
        temp.extracted = true;
        temp.params = null;
        if (card.documentId) {
          temp.documentId = card.documentId;
        }
      }
      return temp;
    });
    this.setState({
      selectedDocumentList: docList
    });
  };

  handleNameCaptureDocSelected = (card) => {
    const { selectedDocumentList } = this.state;
    const documentList = [...selectedDocumentList];
    const itemIndex = documentList.findIndex((crd) => {
      return crd.type === card.type;
    });

    if (itemIndex > -1) {
      documentList[itemIndex] = { ...card, captured: true };
    } else {
      documentList.push({ ...card, captured: true });
    }

    this.setState({
      selectedDocumentList: documentList,
      showNameMatch: false,
      prevScreenState: null,
      step: 1
    });
  };

  handleFinish = (token, additionalInfo = false) => {
    let { tokenId } = this.state;
    if (token) {
      tokenId = token;
    }

    APIs.verifyScans(tokenId, additionalInfo)
      .then(({ redirectTo }) => {
        if (redirectTo) {
          setTimeout(() => {
            APIs.return();
          }, 3000);
          this.setState({
            redirect: true,
            isProcessing: false,
            isUploading: false,
            completed: true
          });
        } else {
          this.setState({ isProcessing: false, isUploading: false, completed: true });
        }
      })
      .catch((err) => {
        console.error(err);
        const error = {
          component: Error500,
          props: {
            onTryAgain: () => {
              this.setState({ step: 2, showCaptureExtra: false, error: null });
            }
          }
        };
        this.setState({ error, isProcessing: false, isUploading: false });
      });
  };

  handleRecapture = (card) => {
    const { selectedDocumentList } = this.state;
    const docList = selectedDocumentList.map((doc) => {
      const temp = { ...doc };
      if (doc.type === card.type) {
        temp.captured = false;
        temp.extracted = false;
        temp.token = null;
        temp.backOfCard = false;
        temp.extracted = false;
      }
      return temp;
    });
    this.setState({
      selectedDocumentList: docList,
      step: 0
    });
  };

  handleCaptureBack = (card) => {
    const { selectedDocumentList } = this.state;
    const docList = selectedDocumentList.map((doc) => {
      const temp = { ...doc };
      if (doc.type === card.type) {
        temp.captured = false;
        temp.token = null;
        temp.backOfCard = true;
      }
      return temp;
    });
    this.setState({
      selectedDocumentList: docList,
      step: 0
    });
  };

  handleGoBackFromCapture = (card = null) => {
    const { selectedDocumentList } = this.state;

    if (!card) {
      if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
        this.setState({
          showDocumentSelection: false,
          selectedDocumentList: [],
          currentDocument: null,
          accepted: false
        });
      } else {
        this.setState({ showDocumentSelection: true });
        if (ENABLE_ONE_DOC_CONDITION) {
          this.setState({ selectedDocumentList: [] });
        }
      }
    } else {
      const docList = selectedDocumentList.map((doc) => {
        const temp = { ...doc };
        if (doc.type === card.type) {
          temp.captured = false;
          temp.token = null;
          delete temp.params;
          delete temp.backOfCard;
        }
        return temp;
      });
      this.setState({
        selectedDocumentList: docList
      });
    }
  };

  handleChooseDiffId = (noRecognizeCard) => {
    this.setState(({ selectedDocumentList }) => ({
      step: 0,
      showDocumentSelection: true,
      selectedDocumentList: selectedDocumentList.filter((doc) => doc.type !== noRecognizeCard.type)
    }));
  };

  /**
   * Render the component's.
   *
   * @return {ReactElement}
   */
  render() {
    const {
      step,
      tokenId,
      cancelled,
      confirm,
      confirmFR,
      error,
      tenMinsLeft,
      accepted,
      compatible,
      completed,
      isProcessing,
      isUploading,
      uploadBar,
      isLandscape,
      geolocation,
      verify,
      webrtc = {},
      showLanguageSelectionPrompt,
      showDocumentSelection,
      selectedDocumentList,
      showNameMatch,
      showCaptureExtra,
      currentDocument,
      selfieFR,
      showWelcome,
      showDetailMatch,
      redirect,
      prevScreenState
    } = this.state;

    const { todo, status: isWebRTC } = webrtc;
    const { component: Error, props: errorProps } = error || {};

    const { flowType, transStatus } = this.props;

    let requiredDocumentConfig = VOI_FLOW_V2_REQUIRED_DOC_CONFIG;
    if (ENABLE_ONE_DOC_CONDITION) {
      requiredDocumentConfig = [
        {
          numberOfDocsRequired: 1
        }
      ];
    }

    /**
     * Button states
     */
    const confirmBtns = [
      {
        label: localizedString('cancel'),
        onClick: () => this.setState({ confirm: false, confirmFR: false }),
        variant: 'transparent'
      },
      {
        label: localizedString('yesImSure'),
        onClick: () => {
          setCookie('retry', null, -7);
          setCookie('retryAttempt', null, -7);

          if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
            this.setState({
              step: 0,
              confirm: false,
              confirmFR: false,
              showDocumentSelection: false,
              selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
              currentDocument: {
                index: 0,
                type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
                isProcessing: false
              }
            });
          } else {
            this.setState({
              step: 0,
              confirm: false,
              confirmFR: false,
              showDocumentSelection: true
            });
          }
          // this.handleGoBack();
        }
      }
    ];
    const openChromeBtns = [
      {
        label: localizedString('proceed'),
        full: true,
        onClick: () => {
          const { id } = document.body.dataset;
          document.location = `googlechrome://navigate?url=${document.location.href}${id}`;
          setTimeout(() => {
            this.setState({ compatible: true });
          }, 1000);
        }
      }
    ];
    const openSafariBtns = [
      {
        children: (
          <a className="link-inside-button" href="x-web-search://" target="_self">
            {localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_BUTTON_IOS')}
          </a>
        ),
        full: true,
        style: { backgroundColor: '#000', borderStyle: 'none' },
        onClick: () => copyOpenSafari(this.input)
      }
    ];
    const tenMinsLeftBtns = [
      {
        label: localizedString('ok'),
        onClick: () => this.setState({ tenMinsLeft: false })
      }
    ];
    /**
     * Exceptions for device compatible.
     */
    if (!compatible) {
      if (todo === 'OPEN_SAFARI') {
        return (
          <Message
            title={localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_TITLE_IOS')}
            buttons={openSafariBtns}
            issue={localizedString('useSafari')}
          >
            {parse(localizedString('app.FLOW_V2_DEVICE_INCOMPATIBLE_ALERT_DESCRIPTION_IOS'))}

            <input
              readOnly
              className="b-hidden-input"
              ref={(ref) => {
                this.input = ref;
              }}
            />
          </Message>
        );
      }
      if (todo === 'NEED_ALT_FLOW') {
        const url = `${window.location.href}?flow=alternate`;
        window.location.replace(url);
      }
    }

    // Check if Browser is Firefox, as Firefox is not permitted for mobile flow
    if (isAndroidDevice() && !isBrowserPermitted()) {
      return <DeviceIncompatibleOpenChrome issue={false} buttons={openChromeBtns} />;
    }

    let screenType = '';

    if (showWelcome) {
      screenType = 'WELCOME';
    } else if (
      transStatus === 'COMPLETED' ||
      transStatus === 'EXPIRED' ||
      transStatus === 'CANCELLED' ||
      transStatus === '404'
    ) {
      screenType = transStatus;
    } else if (Error) {
      screenType = 'ERROR';
    } else if (cancelled) {
      screenType = 'SESSION_CANCELLED';
    } else if (completed) {
      screenType = 'SESSION_COMPLETED';
    } else if (showLanguageSelectionPrompt) {
      screenType = 'LANGUAGE';
    } else if (isShownPrivacy('VOI_FLOW_V2') && !accepted) {
      screenType = 'PRIVACY';
    } else if (showDocumentSelection) {
      screenType = 'DOCUMENT_SELECTION';
    } else if (showNameMatch) {
      screenType = 'NAME_MATCH';
    } else if (showCaptureExtra) {
      screenType = 'CAPTURE_EXTRA';
    } else if (step === 0) {
      screenType = 'CAPTURE';
    } else if (step === 1) {
      screenType = 'VERIFY_DETAILS';
    } else if (step === 2) {
      screenType = isWebRTC && !selfieFR ? 'FACE_SCAN' : 'ALTERNATE_FLOW';
    }

    let mainContainerClass = '';
    if (ENABLE_ONE_DOC_CONDITION) {
      mainContainerClass = 'enable-one-doc-container';
    }

    if (showDetailMatch) {
      return <DetailMatch />;
    }

    const { appConfig } = this.props;

    let verificationExpiredContent;
    if (appConfig.expiryPageContent) {
      verificationExpiredContent = draftToHtml(
        getLocalizedIdvcContent(appConfig.expiryPageContent)
      );
    }

    if (!verificationExpiredContent) {
      verificationExpiredContent = localizedString('verificationExpiredAlert');
    }

    return (
      <div className={mainContainerClass}>
        {isLandscape && <Message landscape />}
        {screenType === 'COMPLETED' && (
          <Message title="Verification Completed" completed>
            This verification has been completed.
            <br />
            <br />
            You may close this window.
          </Message>
        )}
        {screenType === 'EXPIRED' && (
          <Message title={localizedString('app.FLOW_V2_VERIFICATION_EXPIRED_ALERT_TITLE')} issue>
            {parse(verificationExpiredContent)}
          </Message>
        )}
        {screenType === 'CANCELLED' && (
          <Message title="Session Cancelled" issue>
            This verification has been cancelled.
            <br />
            <br />
            You may close this window.
          </Message>
        )}
        {screenType === '404' && (
          <Message title="Incorrect URL" issue>
            The URL provided does not correspond to any verification.
            <br />
            <br />
            Please check the URL and try again.
            <br />
            <br />
            You can close this window or go back.
          </Message>
        )}
        {screenType === 'ERROR' && <Error {...errorProps} />}
        {screenType === 'SESSION_CANCELLED' && (
          <Message title={localizedString('app.FLOW_V2_SESSION_CANCELLED_ALERT_TITLE')} issue>
            {parse(localizedString('app.FLOW_V2_SESSION_CANCELLED_ALERT_DESCRIPTION'))}
          </Message>
        )}
        {screenType === 'SESSION_COMPLETED' && <Success redirect={redirect} />}
        {screenType === 'WELCOME' && (
          <Welcome onStart={() => this.setState({ showWelcome: false })} />
        )}
        {screenType === 'PRIVACY' && (
          <PrivacyScreen
            flowType={flowType}
            onAccept={() => {
              if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
                // Auto select document won't goes to documentSelection, hence need to call selectId api here to record statistics.
                // ENABLE_ONE_DOC_CONDITION should always be off when VOI_FLOW_V2_AUTO_SELECT_DOCS is on hence resetTables is always yes.
                APIs.status('selectId', { resetTables: 'yes' });

                this.setState({
                  accepted: true,
                  showDocumentSelection: false,
                  selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
                  currentDocument: {
                    index: 0,
                    type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
                    isProcessing: false
                  }
                });
              } else {
                this.setState({ accepted: true, showDocumentSelection: true });
              }
            }}
            onSelectLanguage={() => this.setState({ showLanguageSelectionPrompt: true })}
          />
        )}
        {screenType === 'LANGUAGE' && (
          <Language onGoback={() => this.setState({ showLanguageSelectionPrompt: false })} />
        )}
        {screenType === 'DOCUMENT_SELECTION' && (
          <DocumentSelection
            initialSelectedDocumentList={selectedDocumentList}
            currentDocument={currentDocument}
            enableOneDocCondition={ENABLE_ONE_DOC_CONDITION}
            requiredDocumentConfig={requiredDocumentConfig}
            onGoBack={() =>
              this.setState({
                showDocumentSelection: false,
                accepted: false,
                selectedDocumentList: [],
                currentDocument: {
                  index: -1,
                  type: '',
                  isProcessing: false
                },
                step: 0,
                prevScreenState: null
              })
            }
            onNextStep={(selectedDocumentListResult) => {
              let { index, isProcessing, type } = currentDocument;

              if (ENABLE_ONE_DOC_CONDITION && index < 2) {
                isProcessing = true;

                if (index < 0) {
                  index = 0;
                }

                type = selectedDocumentListResult[0].type;
                this.setState({
                  currentDocument: { index, isProcessing, type },
                  step: 0
                });

                // Selected Document Management
                let { selectedDocumentList } = this.state;
                if (!selectedDocumentList || index === 0) {
                  selectedDocumentList = [];
                }

                // eslint-disable-next-line no-restricted-syntax
                for (const resultItem of selectedDocumentListResult) {
                  selectedDocumentList.push(resultItem);
                }

                this.setState({
                  showDocumentSelection: false,
                  selectedDocumentList
                });
              } else if (selectedDocumentListResult.filter((doc) => !doc.captured).length === 0) {
                // if all documents have been captured && extracted, move directly to face scan step
                // if all documents have been captured but not all extracted, move directly to verify detail step
                // this could happen when user failed for document and reselected document type
                this.setState({
                  showDocumentSelection: false,
                  selectedDocumentList: selectedDocumentListResult,
                  step:
                    selectedDocumentListResult.filter((doc) => !doc.extracted).length > 0 ? 1 : 2
                });
              } else {
                this.setState({
                  showDocumentSelection: false,
                  selectedDocumentList: selectedDocumentListResult
                });
              }
            }}
          />
        )}
        {screenType === 'CAPTURE' && (
          <Capture
            onGoBack={this.handleGoBackFromCapture}
            onNextStep={(obj) => {
              this.setState({
                ...obj
              });
            }}
            selectedDocumentList={selectedDocumentList}
            onGeoLocation={(geolocation) => this.setState({ geolocation })}
            onCaptured={this.handleOnCaptured}
            flowType={flowType}
            verify={verify}
            onExit={() => this.setState({ confirm: true })}
          />
        )}
        {screenType === 'VERIFY_DETAILS' && (
          <VerifyDetails
            currentDocument={currentDocument}
            selectedDocumentList={selectedDocumentList}
            verify={verify}
            onExtracted={this.handleOnExtracted}
            flowType={flowType}
            location={geolocation}
            prevScreenState={prevScreenState}
            onNextStep={(obj) => {
              if (ENABLE_ONE_DOC_CONDITION && obj.secondDocument === true) {
                if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
                  this.setState({
                    showDocumentSelection: false,
                    selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
                    currentDocument: {
                      index: 0,
                      type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
                      isProcessing: false
                    }
                  });
                } else {
                  this.setState({
                    selectedDocumentList: [],
                    currentDocument: {
                      index: 1,
                      isProcessing: false,
                      type: currentDocument.type
                    },
                    showDocumentSelection: true
                  });
                }
                return;
              }

              this.setState({ ...obj });
            }}
            onRecapture={this.handleRecapture}
            onCaptureBack={this.handleCaptureBack}
            onChooseDiffId={this.handleChooseDiffId}
            onGoBack={(card = null) => {
              if (card) {
                const docList = selectedDocumentList.map((doc) => {
                  if (doc.type === card.type) {
                    return { ...card, captured: false };
                  }
                  return { ...doc };
                });

                this.setState({
                  selectedDocumentList: docList
                });
              }

              if (VOI_FLOW_V2_AUTO_SELECT_DOCS && VOI_FLOW_V2_AUTO_SELECT_DOCS.length > 0) {
                this.setState({
                  step: 0,
                  prevScreenState: null,
                  showDocumentSelection: false,
                  selectedDocumentList: VOI_FLOW_V2_AUTO_SELECT_DOCS,
                  currentDocument: {
                    index: 0,
                    type: VOI_FLOW_V2_AUTO_SELECT_DOCS[0].type,
                    isProcessing: false
                  }
                });
              } else {
                this.setState({ step: 0, showDocumentSelection: true, prevScreenState: null });
              }
            }}
          />
        )}
        {screenType === 'NAME_MATCH' && (
          <NameMatch
            currentDocument={currentDocument}
            flowType={flowType}
            onUpdateSelectedDocList={this.handleNameCaptureDocSelected}
            onGoBack={() => this.setState({ showNameMatch: false })}
            onNextStep={(obj) => {
              this.setState({
                ...obj,
                prevScreenState: null
              });
            }}
          />
        )}
        {screenType === 'FACE_SCAN' && (
          <FaceScan
            tokenId={tokenId}
            onNextStep={this.handleComplete}
            onGoBack={this.handleFRGoBack}
            onSelfie={() => this.setState({ selfieFR: true })}
          />
        )}
        {screenType === 'ALTERNATE_FLOW' && (
          <AlternateFlow
            tokenId={tokenId}
            onNextStep={this.handleCompleteAlternate}
            onGoBack={this.handleFRGoBack}
          />
        )}
        {screenType === 'CAPTURE_EXTRA' && (
          <CaptureExtra
            flowType={flowType}
            onNextStep={(tokenId = null, additionalInfo = false) =>
              this.handleFinish(tokenId, additionalInfo)
            }
          />
        )}
        {tenMinsLeft && (
          <Modal isOpen heading={localizedString('tenMinsLeftDesc')} buttons={tenMinsLeftBtns} />
        )}
        <Modal
          isOpen={confirm}
          heading={localizedString('app.FLOW_V2_EXIT_SCREEN_TITLE')}
          description=""
          buttons={confirmBtns}
        >
          {confirmFR
            ? localizedString('app.FLOW_V2_EXIT_SCREEN_DESCRIPTION_DETAILS')
            : localizedString('app.FLOW_V2_EXIT_SCREEN_DESCRIPTION_CAPTURE')}
        </Modal>
        {isUploading && <LoadingBar heading={localizedString('uploading')} width={uploadBar} />}
        {isProcessing && (
          <LoadingSpinner subtitle="" heading={localizedString('verifyingYourIdentity')} />
        )}
      </div>
    );
  }
}

App.propTypes = {
  verify: PropTypes.bool,
  flowType: PropTypes.string,
  transStatus: PropTypes.string,
  appConfig: PropTypes.object
};

export default connect(mapStateToProps, null)(App);

/**
 * Map the store's state to the component's props
 * @param  {Object} state
 * @return {Object}
 */
function mapStateToProps({ information, appConfig }) {
  return {
    appConfig,
    idDetails: information.idDetails,
    addresses: information.addresses
  };
}
